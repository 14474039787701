<template>
  <b-sidebar
    id="add-new-project-sidebar"
    :visible="isAddNewProjectSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-project-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.projects.createNewProject") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewproject())"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="nome"
            rules="required"
          >
            <b-form-group
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                id="name"
                v-model="projectData.name"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="descrição"
            rules="required"
          >
            <b-form-group
              label-for="description"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.description") }}
              </template>
              <quill-editor
                id="title"
                style="margin-bottom: 82px; height: 300px"
                :options="editorOption"
                v-model="projectData.description"
              />
            </b-form-group>
          </validation-provider>

          <b-form-group label-for="wildcard">
            <template v-slot:label> {{ $t("uploadFile") }} </template>
            <b-form-file
              id="wildcard"
ref="file-input"
              class="mr-1"
              v-model="file"
              browse-text="Procurar"
              placeholder="Nenhum arquivo selecionado"
              drop-placeholder="Arraste arquivo aqui"
              :accept="fileFormats"
            >
            </b-form-file>
          </b-form-group>

          <!-- Start Date -->
          <validation-provider
            #default="validationContext"
            name="dateStart"
            rules="required"
          >
            <b-form-group label-for="startDate">
              <template v-slot:label>
                {{ $t("message.date.start") }}
              </template>
              <flat-pickr
                v-model="projectData.startDate"
                class="form-control"
                :config="flatConfig"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.dateStart") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- End Date -->
          <validation-provider
            #default="validationContext"
            name="endDate"
            rules="required"
          >
            <b-form-group label-for="endDate">
              <template v-slot:label>
                {{ $t("message.date.end") }}
              </template>
              <flat-pickr
                v-model="projectData.endDate"
                class="form-control"
                :config="flatConfig"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.dateEnd") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Estimated Time -->
          <validation-provider
            #default="validationContext"
            name="tempo estimado"
            rules="required"
          >
            <b-form-group
              label-for="estimatedTime"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.estimatedTime") }}
              </template>

              <number-input
                v-model="projectDataEstimatedTime"
                center
                controls
                :state="getValidationState(validationContext)"
                :min="0"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Team -->
          <validation-provider
            #default="validationContext"
            name="team"
            rules="required"
          >
            <b-form-group
              label-for="teams"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.team.team") }}
              </template>
              <v-select
                multiple
                v-model="projectData.teams"
                @input="projectManagers(projectData.teams)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="teamOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="team-role"
              />
            </b-form-group>
          </validation-provider>

          <b-form-group label-for="manager">
            <template v-slot:label>
              {{ $t("message.team.managerId") }}
            </template>
            <b-form-input v-if="managerList.length === 0" readonly />
            <v-select
              v-else
              multiple
              v-model="projectData.managers"
              :options="managerList"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="manager"
            />
          </b-form-group>

          <!-- Status 
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tableHeader.status") }}
              </template>
              <v-select
                v-model="projectData.status"
                :options="projectStatusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="project-status"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>-->

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import date from "@/@core/utils/date";
import dateInput from "@/@core/utils/dateInput";
import Vue from "vue";
import VueNumberInput from "@chenfengyuan/vue-number-input";
Vue.use(VueNumberInput);
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import getHeaderFile from "@core/utils/authFile";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,
    BFormFile,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewProjectSidebarActive",
    event: "update:is-add-new-project-sidebar-active",
  },
  props: {
    isAddNewProjectSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectStatusOptions: {
      type: Array,
      required: true,
    },
    teamOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      flatConfig: {
        dateFormat: "d/m/Y",
      },

      openProjects: true,
      teamsByMember: [],

      //Param
      paramData: store.state.user.paramData,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",

      //CurrentUser
      userData: store.state.user.userData,

      projectDataEstimatedTime: 0,

      disabledButton: false,

      userOptions: [],
      managerList: [],
      teamData: [],

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
    "_props.isAddNewProjectSidebarActive": function (newVal, oldVal) {
      if (newVal) {
        this.projectDataEstimatedTime = 0;
      }
    },
    file: function (newVal, oldVal) {
      if (newVal != null) {
        const maxAllowedSize = this.fileMaxSize * 1024 * 1024;
        if (newVal.size > maxAllowedSize) {
          // Here you can ask your users to load correct file
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Não permitido",
              icon: "XIcon",
              variant: "danger",
              text: "Arquivo com tamanho maior do que permitido pelo sistema",
            },
          });
          this.$refs['file-input'].reset()
        }
      }
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  async created() {
    await axios
      .get(`${URL_API}team/teamsByMember/${this.userData.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.content.length; i++) {
          this.teamsByMember.push(response.data.content[i].id);
        }
      });

    var userList = [];
    var userId = [];
    await axios
      .get(`${URL_API}user/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].email;
          const id = response.data[i].id;
          userList.push(element);
          userId.push(id);
          this.userOptions.push({ label: userList[i], value: userId[i] });
        }
      });

    this.loadFileFormats();
  },

  methods: {
    loadFileFormats() {
      this.fileFormats = this.paramData.fileTypes.join(", ").toString();
      this.fileMaxSize = this.paramData.maxFileSize;
    },

    addFile(projectId) {
      if (this.file != null) {
        let formData = new FormData();
        formData.append("file", this.file);

        axios
          .post(
            `${URL_API}file/project/${this.userData.id}/${projectId}`,
            formData,
            {
              headers: getHeaderFile(this.userData),
            }
          )
          .then((response) => {
          if (!response.data.id && response.data != "") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("noDataAvailable"),
                icon: "XIcon",
                variant: "danger",
                text: this.$i18n.t("noDataAvailableDesc"),
              },
            });
          } else if(response.data === "") {
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          }
        }); 
      }
    },    

    async projectManagers(managers) {
      this.teamData = [];
      this.managerList = [];

      for (var k = 0; k < managers.length; k++) {
        await axios
          .get(`${URL_API}team/${managers[k]}`, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            this.teamData.push(response.data);
          });
      }

      var listOfManager = []; //Pegar todos os ids dos managers
      for (var k = 0; k < this.teamData.length; k++) {
        for (var i = 0; i < this.teamData[k].managers.length; i++) {
          listOfManager.push(this.teamData[k].managers[i]);
        }
      }

      let uniqueItems = [...new Set(listOfManager)]; //Remover duplicados

      for (var i = 0; i < uniqueItems.length; i++) {
        for (var k = 0; k < this.userOptions.length; k++) {
          if (this.userOptions[k].value === uniqueItems[i]) {
            this.managerList.push({
              label: this.userOptions[k].label,
              value: this.userOptions[k].value,
            });
          }
        }
      }
    },

    submitNewproject() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          this.projectData.startDate = date(
            this.$i18n.locale,
            this.projectData.startDate
          );
          this.projectData.endDate = date(
            this.$i18n.locale,
            this.projectData.endDate
          );

          this.projectData.status = "_PENDING";

          this.projectData.estimatedTime = this.projectDataEstimatedTime;

          axios({
            method: "post",
            url: `${URL_API}project`,
            headers: getHeader(this.userData),
            data: this.projectData,
          })
            .then((response) => {
              this.addFile(response.data.id);
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `Projeto ${this.projectData.name} criado com sucesso`,
                },
              });
            })
            .then((response) => {
              this.$root.$emit("newListProject", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },
  },

  setup(props, { emit }) {
    const blankprojectData = {};

    const projectData = ref(JSON.parse(JSON.stringify(blankprojectData)));
    const resetprojectData = () => {
      projectData.value = JSON.parse(JSON.stringify(blankprojectData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-project-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetprojectData);

    return {
      projectData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-project-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
